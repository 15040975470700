import React from 'react'
import ThemeContext, { ThemeProvider } from './src/utils/theme'
import './src/styles/global.scss'
import { Provider } from 'react-supabase'
import createClient from './src/provider/client/Supabase'
import AuthProvider from './src/contexts/auth-context'

const supabase = createClient()

/** This component wraps around the entire root div where we render our content.
 *  So, this piece of code is applying the theme-light/dark class on the top level
 */
export const wrapRootElement = ({ element }) => (
    <ThemeProvider>
        <ThemeContext.Consumer>
            {({ toString }) => (
                <Provider value={supabase}>
                    <AuthProvider>
                        <div className={`theme-${toString()}`}>{element}</div>
                    </AuthProvider>
                </Provider>
            )}
        </ThemeContext.Consumer>
    </ThemeProvider>
)
