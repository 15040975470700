import React, { createContext, useEffect, useState } from 'react'
import { useAuthStateChange, useClient } from 'react-supabase'
import { useLocalStorage } from 'usehooks-ts'

const initialState = { session: null, user: null, isLoading: true }
export const AuthContext = createContext(initialState)

export default function AuthProvider({ children }) {
    const client = useClient()
    const [state, setState] = useState(initialState)
    const [, setSearchUserToken] = useLocalStorage('search:userid', null)

    useEffect(() => {
        client.auth.getSession().then(result => {
            setState({
                session: result.data?.session,
                user: result.data?.session?.user ?? null,
                isLoading: false,
            })
            setSearchUserToken(result.data?.session?.user?.id)
        })
    }, [client.auth, setSearchUserToken])

    useAuthStateChange((event, session) => {
        setState({ session, user: session?.user ?? null, isLoading: false })
        if (event === 'SIGNED_OUT') {
            setSearchUserToken(null)
        }
    })

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}
