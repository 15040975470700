exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin/[...].js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-dr-dominator-top-10-js": () => import("./../../../src/pages/dr-dominator-top-10.js" /* webpackChunkName: "component---src-pages-dr-dominator-top-10-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-new-story-js": () => import("./../../../src/pages/new-story.js" /* webpackChunkName: "component---src-pages-new-story-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-stories-by-author-js": () => import("./../../../src/pages/stories-by-author.js" /* webpackChunkName: "component---src-pages-stories-by-author-js" */),
  "component---src-pages-stories-by-date-js": () => import("./../../../src/pages/stories-by-date.js" /* webpackChunkName: "component---src-pages-stories-by-date-js" */),
  "component---src-pages-stories-by-sentiment-js": () => import("./../../../src/pages/stories-by-sentiment.js" /* webpackChunkName: "component---src-pages-stories-by-sentiment-js" */),
  "component---src-pages-stories-by-tag-js": () => import("./../../../src/pages/stories-by-tag.js" /* webpackChunkName: "component---src-pages-stories-by-tag-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-stories-visits-alltime-js": () => import("./../../../src/pages/stories-visits-alltime.js" /* webpackChunkName: "component---src-pages-stories-visits-alltime-js" */),
  "component---src-pages-stories-visits-week-js": () => import("./../../../src/pages/stories-visits-week.js" /* webpackChunkName: "component---src-pages-stories-visits-week-js" */),
  "component---src-pages-stories-visits-yesterday-js": () => import("./../../../src/pages/stories-visits-yesterday.js" /* webpackChunkName: "component---src-pages-stories-visits-yesterday-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users/[...].js" /* webpackChunkName: "component---src-pages-users-js" */),
  "component---src-templates-stories-post-js": () => import("./../../../src/templates/stories-post.js" /* webpackChunkName: "component---src-templates-stories-post-js" */),
  "component---src-templates-storytag-post-tsx": () => import("./../../../src/templates/storytag-post.tsx" /* webpackChunkName: "component---src-templates-storytag-post-tsx" */)
}

