import React, { Component } from 'react'

const defaultState = {
    dark: false,
    toString: () => `light`,
    toggleDark: () => {},
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends Component {
    state = {
        dark: window.localStorage.getItem('theme-state') === 'true',
    }

    toString = () => (this.state.dark ? `dark` : `light`)

    toggleDark = () => {
        let dark = !this.state.dark
        this.setState({ dark })
        window.localStorage.setItem('theme-state', dark.toString())

        try {
            if (window.gtag) {
                window.gtag('event', 'ThemeSwitch', {
                    event_category: 'engagement',
                    event_label: 'toggle',
                    value: dark ? 1 : 0,
                })
            }
        } catch (e) {
            console.error('logging google analytics event failed', e)
        }
    }

    render() {
        const { children } = this.props
        const { dark } = this.state
        return (
            <ThemeContext.Provider
                value={{
                    dark,
                    toggleDark: this.toggleDark,
                    toString: this.toString,
                }}
            >
                {children}
            </ThemeContext.Provider>
        )
    }
}

export default ThemeContext

export { ThemeProvider }
