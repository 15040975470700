import { createClient } from '@supabase/supabase-js'

/**
 * Create client to connect with Supabase
 */
export default function createSupabaseClient() {
    return createClient(
        process.env.GATSBY_SUPABASE_PROJECT_URL,
        process.env.GATSBY_SUPABASE_ANON_KEY,
        {
            auth: {
                persistSession: true,
                autoRefreshToken: true,
                detectSessionInUrl: true,
            },
        }
    )
}
